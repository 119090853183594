<template>
  
  <div class="lg:flex mt-2 justify-center ">
    <div class="b-card lg:p-6 lg:mt-32 lg:px-32">
    <BPageLoader v-if="verifyResponse.isLoading" />
    <div
      class="px-6 py-4 text-center flex flex-col items-center"
      v-else-if="verifyResponse.isFailure"
    >
      <div class="flex items-center justify-center rounded-full my-6 h-28 w-28 bg-bg-alternate-3">
        <SearchOffIcon />
      </div>
      <h2 class="text-xl max-w-sm text-text-primary font-semibold my-4">Expired or Invalid Link</h2>
      <p class="text-xs text-text-alternate-3 font-medium my-4">
        The email verification link is not valid or has expired, re-initiate a new link with the
        button below.
      </p>
      <div class="flex flex-col py-6 gap-4 w-full">
        <button
          class="bg-btn-bg-primary text-btn-text-primary py-4 rounded text-sm text-center font-bold"
          @click="isEmailModal = true"
        >
          Send Verification Link
        </button>
      </div>
    </div>
    <div
      class="px-6 py-4 text-center flex flex-col items-center mt-10"
      v-else-if="verifyResponse.isSuccess"
    >
      <SuccessIcon class="mb-6 mt-4 mx-auto" />
      <h2 class="text-lg max-w-sm text-text-primary font-semibold my-4">
        You Email has been Verifed
      </h2>
      <router-link
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        :to="{ name: 'Feeds' }"
      >
        View Fundraisers
      </router-link>
    </div>
    </div>

  </div>
  <BModal :isOpen="isEmailModal" @close="isEmailModal = false">
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <form @submit.prevent="handleSubmit" v-else>
      <div class="flex flex-col text-text-primary font-semibold text-sm mb-24">
        <label>Email</label>
        <input
          class="
            border
            rounded
            border-border-primary
            mt-2
            mb-1
            p-4
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          type="email"
          v-model.trim="email"
        />
      </div>
      <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-14">
        <button
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            px-4
            py-3.5
            xs:py-4
            w-full
            rounded
            text-center text-sm
            xs:text-base
            font-bold
          "
          :disabled="!email"
          type="submit"
        >
          Continue
        </button>
      </div>
    </form>
  </BModal>
  <BModal :isOpen="isSuccess">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="mb-6 mt-4 mx-auto" />
      <p class="text-lg max-w-sm text-text-primary font-semibold mt-4 mb-2">Sent successfully</p>
      <p class="text-xs text-text-alternate-3 font-medium">
        Link sent successfully to your email address
      </p>

      <router-link
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        :to="{ name: 'Feeds' }"
      >
        Done
      </router-link>
    </div>
  </BModal>
  <BModal :isOpen="isFailure" @close="closeFailureModal">
    <div class="text-center flex flex-col items-center pb-4">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs text-text-alternate-3">
        {{ error }}
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="closeFailureModal"
      >
        Close
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, toRefs } from 'vue';
import BModal from '@/components/ui/BModal';
import { useApi } from '@/cmp-functions/useApi';
import { sendEmailVerification, verifyEmail } from '@/services/api';
import { useRoute } from 'vue-router';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';

export default {
  name: 'EmailVerification',

  components: { BModal },

  setup() {
    const email = ref('');
    const showModal = ref(false);
    const isEmailModal = ref(false);
    const route = useRoute();
    const { ID } = route.query;

    const [response, requestEmailVerification, { setIsSuccess, setIsFailure }] =
      useApi(sendEmailVerification);
    const [verifyResponse, emailVerification] = useApi(verifyEmail);

    emailVerification({ token: ID });

    const handleSubmit = () => {
      requestEmailVerification({ email: email.value });
    };

    const { state } = useSideMenuState();

    const closeSuccessModal = () => {
      setIsSuccess(false);
    };

    const closeFailureModal = () => {
      isEmailModal.value = false;
      setIsFailure(false);
    };

    return {
      showModal,
      handleSubmit,
      email,
      isEmailModal,
      closeSuccessModal,
      closeFailureModal,
      ...toRefs(response),
      ...toRefs(state),
      verifyResponse,
    };
  },
};
</script>
